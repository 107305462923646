import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../layouts/mdx';
export const _frontmatter = {
  "category": "Welcome",
  "title": "Criipto Documentation",
  "subtitle": "Welcome to the API documentation for Criipto Verify & Criipto Document Signatures."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Criipto Verify`}</h2>
    <p>{`Criipto Verify provides eID authentication as a service.`}</p>
    <p>{`For an introduction to Criipto Verify and eID, you may want to read `}<a parentName="p" {...{
        "href": "/verify/getting-started/overview"
      }}>{`Getting Started`}</a>{`.`}</p>
    <p>{`All eID services supports user authentication. With Criipto Verify you integrate using `}<em parentName="p">{`OpenID Connect`}</em>{` (aka OIDC). A general introduction to OIDC with Criipto Verify is available in the section on `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro"
      }}>{`Using OIDC`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/verify"
      }}>{`Criipto Verify Documentation`}</a></p>
    <h2>{`Criipto Signatures`}</h2>
    <p>{`Criipto Signatures allows you to sign any PDF document using all the national eIDs supported by Criipto Verify.`}</p>
    <p>{`Documents will be produced according to the `}<a parentName="p" {...{
        "href": "https://www.criipto.com/blog/pades-signature-levels-explained"
      }}>{`PAdES-LTA standard`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/signatures"
      }}>{`Criipto Signatures Documentation`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      